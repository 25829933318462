.card {
    width:20rem;
    padding-top: 1rem;
}

.card h4 {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
}



.card-text{
    font-size:1.1rem;
    padding:0.4re 1.9rem;
}

.service-icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #f8f8f8;
    border-radius: 50%;
    color: #2c5ced;
    margin-bottom: 23px;
    position: relative;
    z-index: 11;
    box-shadow: 0 0.75rem 1.5rem
    rgba(18, 38, 63, 0.03);
}

.icon-color{
    filter: invert(50%) sepia(33%) saturate(2593%) hue-rotate(169deg) brightness(101%) contrast(103%);
}
