.rootCR {
    background: url('../../../public/Gallery/bg/1.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}

.rootABUS {
    background: url('../../../public/Gallery/bg/2.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}

.rootOS {
    background: url('../../../public/Gallery/bg/3.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}

.rootGal {
    background: url('../../../public/Gallery/bg/4.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}


.rootCont {
    background: url('../../../public/Gallery/bg/5.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}

.rootBAA {
    background: url('../../../public/Gallery/bg/6.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}

.rootOff {
    background: url('../../../public/Gallery/bg/7.png');
    height: 350px;
    background-size: 100% 300%;
    padding-top: 6rem;
}



.nav {
    /* padding-top: 10rem; */
    color: white;
    position:relative;
    z-index:2;
    
    background:rgba(0,0,0,0.85);
    padding:2rem;
    border:1px solid rgba(0,0,0,0.1);
    border-radius:5px;
    /* margin-left: 30rem;
    margin-right: 30rem; */
}

.link {
    text-decoration: none !important;
    color: #fff !important;
}

.link,i {
    margin-right: 1rem;
}

.link:hover {
    color: #77cc5e !important;
}

.title {
    font-size: 3.125rem !important;
    font-weight: 600 !important;
    color: #fff;
    margin: 0 0 0.3em;
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {
    .root {
        height: 300px;
    }

    .nav {
        padding-top: 6rem;
    }
}