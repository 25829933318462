.midhead span{
    text-transform: uppercase !important;
    font-size: 18px;
    color: #3764EB;
}

.midhead h1{
    font-size: 36px;
    font-weight: 700 !important;
    color: #444;
}

.midhead p{
    font-size: 16px;
    color: #666;
}