/* .carousel-item img {
    min-height: 1px;
    overflow: hidden;
} */


.carouselmain {
    margin: 0px 0px -5px 0px;
    text-align: center;
    background-color: yellow;
    padding-top: 5px;
    padding-bottom: 2px;
}
  
.carouselmain p{
    font-family: 'Roboto' !important;
    font-size: 18px;
    font-weight: 700 !important;
    color: #0080ff;
    margin: 0px 0px 15px 0px;
}

.carouselmain a{
    font-family: 'Roboto' !important;
    font-size: 14px;
    font-weight: 500 !important;
    margin: 0px 0px 0px 0px;
}