.form-container {
    background: rgba(255, 255, 255, 0.9);
    background-size: contain;
    color: black;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: bolder !important;
}

.input-black {
    color: black !important;
    border: unset !important;
    background: rgba(22, 11, 11, 0.2) !important;
}

.option-black {
    color: black !important;
    border: unset !important;
    background: rgb(0, 0, 0);
}

.input-black::placeholder {
    color: black !important;
}

.form-background{
    background: url("../../../public/Gallery/Photos/2022-05-06.jpeg");
    background-size:contain;
}