.root {
    background-color: #05386B;
    color: white;
}

.content {
    margin-bottom: 1rem;
}

.img-white {
    margin: 1rem 0 0.5rem 0;
    filter: brightness(0) invert(1);
}

.root h2{
    color: white;
}